import { FC } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import loadable, { LoadableClassComponent } from "@loadable/component";

import routes from "../../config/routes";
import guard from "../../helpers/guard";
import { IRoute } from "types";
import { useApp } from "contexts/AppContext";
import Layout from "../main/Layout";

const Switcher: FC = (): JSX.Element => {
  const { authToken } = useApp();
  return (
    <BrowserRouter>
      <Layout isAuthenticaded={!!authToken}>
        <Switch>
          {routes.map((route: IRoute) => {
            const isAllowed: boolean = guard(route);
            const componentRoute: string = !isAllowed
              ? "NotAllowed"
              : route.component;
            const Component: LoadableClassComponent<any> = loadable(
              () => import(`pages/${componentRoute}`)
            );
            return (
              <Route key={route.path} exact path={route.path}>
                <Component />
              </Route>
            );
          })}
        </Switch>
      </Layout>
    </BrowserRouter>
  );
};

export default Switcher;
