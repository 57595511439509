import { ResourceKey } from 'i18next'

const translation: ResourceKey = {
  "pt-BR": {
    translation: {
      hello: "Olá!",
      "username is a required field": "Usuário é um campo obrigatório",
      "password is a required field": "Senha é um campo obrigatório",
      "Login": "Login",
      "Senha": "Senha",
      "Assinatura Digital": "Assinatura Digital",
      "Entrar": "Entrar",
      "Internal Server Error": "Erro interno do servidor, tente novamente mais tarde.",
      "invalid_grant": "Usuário e/ou senha incorretos, tente novamente.",
      "unauthorized": "Não autorizado.",
      "invalid_token": "Token expirou, faça login novamente.",
      "name": "Nome",
      "newDocument": "Documento",
      "documentType": "Tipo do Documento",
      "country": "País",
      "birthday": "Data de nascimento",
      "socialName": "Nome Social",
      "name is a required field": "Nome é um campo obrigatório",
      "newDocument is a required field": "Documento é um campo obrigatório",
      "documentType is a required field": "Tipo do Documento é um campo obrigatório",
      "country is a required field": "País é um campo obrigatório",
      "birthday is a required field": "Data de nascimento é um campo obrigatório",
      'Documento alterado com sucesso': 'Documento alterado com sucesso',
      "Salvar": "Salvar",
      "OK": "Ok",
      "PENDING": "Pendente",
      "Você fez login sem sua assinatura digital e não poderá realizar algumas ações no portal.": "Você fez login sem sua assinatura digital e não poderá realizar algumas ações no portal.",
      'portuguese': "Português",
      'espanish': "Espanhol",
      'english': "Inglês",
      "Aguardando confirmação": "Aguardando confirmação",
      'portuguese (Portugal)': "Português (Portugal)",
      'Laudos': 'Laudos',
      'Fila': 'Fila',
      'Arquivo': 'Arquivo',
      'Permissões': 'Permissões',
      'Logout': 'Sair',
      'female': "Feminino",
      'male': 'Masculino',
      'transgender': 'Transgênero',
      'transfemale': 'Transgênero feminino',
      'transmale': 'Transgênero masculino',
      "Status da análise": "Status da análise",
      "Sexo": "Sexo",
      "Email": "Email",
      "Celular": "Celular",
      "Endereço": "Endereço",
      "Liberar": "Liberar",
      "Reservar": "Reservar",
      "Confirmar": "Confirmar",
      "Documento confirmado com sucesso": "Documento confirmado com sucesso",
      "Dados Cadastrais": "Dados Cadastrais",
      "Status": "Status",
      "Digite aqui": "Digite aqui",
      "Cpf": "Cpf",
      "Passaporte": "Passaporte",
      "Certidão de nascimento": "Certidão de nascimento",
      "CNS": "CNS",
      "Exame confirmado, gerando laudo!": "Exame confirmado, gerando laudo!",
      "Exame": "Exame",
      "Recoletar": "Recoletar",
      "Laudo gerado": "Laudo gerado",
      "Documento validado": "Documento validado",
      "Gerando laudo": "Gerando laudo",
      "Erro ao gerar o laudo": "Erro ao gerar o laudo",
      "Erro ao validar documento": "Erro ao validar documento",
      "Pendente": "Pendente",
      "Histórico do paciente": "Histórico do paciente",
      "Histórico do especialista": "Histórico do especialista",
      "Observações": "Observações",
      "Adicionar observações do laboratório no laudo": "Adicionar observações do laboratório no laudo",
      "Salvar observações": "Salvar observações",
      "Resultados": "Resultados",
      "Resultado": "Resultado",
      "OD": "OD",
      "Zerar": "Zerar",
      "Positivar": "Positivar",
      "Dados da cápsula": "Dados da cápsula",
      "Cápsula": "Cápsula",
      "Lote interno": "Lote interno",
      "Lote externo": "Lote externo",
      "Leitor": "Leitor",
      "Gráfico de análise": "Gráfico de análise",
      "Aplicar": "Aplicar",
      "Sem questionário": "Sem questionário",
      "Questionário": "Questionário",
      "Clique para visualizar a foto do exame": "Clique para visualizar a foto do exame",
      "Nome": "Nome",
      "Usuário": "Usuário",
      "Validar": "Validar",
      "Permissão atualizada": "Permissão atualizada",
      "Sem Permissão": "Sem permissão",
      "Cancelar": "Cancelar",
      "Pesquisar por Nome": "Pesquisar por nome",
      "Insira sua Assinatura Digital": "Insira sua assinatura digital",
      "Prioridade Ajustada": "Prioridade ajustada",
      "Prioridade": "Prioridade",
      "Filtros": "Filtros",
      "Ações": "Ações",
      "Filtro Cadastrado": "Filtro cadastrado",
      "Documento não encontrado": "Documento não encontrado",
      "Documento encontrado": "Documento encontrado",
      "Marcar interferente na análise de": "Marcar interferente na análise de {{examType}}",
      "LATERAL_FLOW": "Lateral Flow",
      "UV": "UV",
      "COLORIMETRY": "Colorimetria",
      "MICROSCOPY": "Microscopia",
      "MOLECULAR": "Molecular",
      'Próximo Documento': 'Próximo Documento',
      'Próximo Exame': 'Próximo Exame',
      "Confirmar/Próximo": "Confirmar/Próximo",
      "Não foi possível confirmar o documento": "Não foi possível confirmar o documento",
      "Documento invalidado com sucesso": "Documento invalidado com sucesso",
      "Erro ao invalidar o documento": "Erro ao invalidar o documento",
      "Marcar interferente na análise de documento": "Marcar interferente na análise de documento",
      "Invalidar": "Invalidar",
      "ILLEGIBLE_PHOTO": "Foto ilegível",
      "PHOTO_WITHOUT_DOCUMENT": "Foto sem documento",
      "DOCUMENT_WITH_PHOTO_IN_REVERSE": "Documento com foto ao contrário",
      "DOCUMENT_WITHOUT_PHOTO": "Documento sem foto",
      "REGISTRATION_DIVERGENCE": "Divergência de cadastro",
      "CHANGE_PHOTO": "Sem foto",
      "ERROR": "Erro",
      "Erro ao atualizar permissão": 'Erro ao atualizar permissão',
      "Alterado da retificação": "Alterado da retificação",
      "Error ao Retificar": "Error ao Retificar",
      "Reficar": "Reficar",
      "Exame deletado com sucesso": "Exame deletado com sucesso",
      "Erro ao deletar o exame": "Erro ao deletar o exame",
      "Tem certeza que quer remover exame da fila?": "Tem certeza que quer remover exame da fila?",
      "Deletar": "Deletar",
      "Novo exame na fila": "Novo exame na fila",
      "Selecionar categoria": "Selecionar categoria",
      "Capsula": "Cápsula",
      "Documento": "Documento",
      "Codigo da Capsula": "Código da cápsula",
      "Codigo do Documento": "Código do Documento",
      "Data inicial": "Data inicial",
      "Data final": "Data final",
      "Data final deve ser maior que a data inicial": "Data final deve ser maior que a data inicial",
      "Exames finalizados": "Exames finalizados",
      "Pesquisar por datas": "Pesquisar por datas",
      "Exames invalidos": "Exames inválidos",
      "Minhas validaçoes": "Minhas validações",
      "Pesquisar": "Pesquisar",
      "Nenhum resultado encontrado": "Nenhum resultado encontrado",
      "Nenhuma data encontrada": "Nenhuma data encontrada",
      "Horario": "Horário",
      "Horas": "Horas",
      "Abrir PDF": "Abrir PDF",
      "Paciente": "Paciente",
      "utcValue": "pt-BR",
      "Place": "Local",
      "Code": "Código",
      "Date": "Data",
      "Priority": "Prioridade",
      "GENERATING_REPORT": "Gerando laudo",
      "Esse exame será retificado, deseja continuar?": "Esse exame será retificado, deseja continuar?",
      "Amostra inicial": "Amostra inicial",
      "Amostra final": "Amostra final",
      "Buffer inicial": "Buffer inicial",
      "Buffer final": "Buffer final",
      "Sample": "Amostra",
      "Buffer": "Buffer",
      "Posição do buffer": "Posição do buffer",
      "Posição da amostra": "Posição da amostra",
      "Delta": "Delta",
      'LYMPHOCYTES': 'Linfócitos',
      'EOSINOPHILS': 'Eosinófilos e Basófilos',
      'MONOCYTES': 'Monócitos',
      'NEUTROPHILS': 'Neutrófilos',
      'UNKNOWN': 'Desconhecidas',
      'ERYTHROCYTES': 'Hemácias',
      'THROMBOCYTES': 'Plaquetas',
      'Expira em': 'Expira em',
      'dias': 'dias',
      'Permissão expirada': 'Permissão expirada',
      'Expira hoje': 'Expira hoje',
      'Local sem dispensa de foto': 'Local sem dispensa de foto',
      'Local com dispensa de foto': 'Local com dispensa de foto',
      'Nome encontrado': 'Nome encontrado',
      'Aniversário encontrado': 'Aniversário encontrado',
      "Deseja realmente deletar este filtro?": "Deseja realmente deletar este filtro?",
      "Novo filtro": "Novo filtro",
      "Nome do filtro": "Nome do filtro",
      "Busque CNPJ": "Busque CNPJ",
      "C4i0_ERROR": "Erro no C4i0, por favor entre em contato com o suporte",
      "Sim": "Sim",
      "Não": "Não",
      "Deseja confirmar esse exame?": "Deseja confirmar esse exame?",
      "Curva ultilizada": "Curva utilizada",
      "Curva": "Curva",
      "Result": "Resultado",
      "Tipo de amostra": "Tipo de amostra",
      "ID da amostra": "ID da amostra",
      "Cuidado!": "Cuidado!",
      "Esse exame possui valores que exigem atenção!": "Esse exame possui valores que exigem atenção!",
      "Por quem": "Por quem",
      "Motivo": "Motivo",
      "Laudo invalido": "Laudo inválido",
      "Gráfico de transmitância": "Gráfico de transmitância",
      "TIME": "TEMPO (s)",
      "POTENTIAL": "POTÊNCIA (V)",
      "SAVE": "Salvo com sucesso!",
      "Technical manager token is expired": "Assinatura expirada",
      "DEFAULT_DOCUMENT_ERROR_MESSAGE": "Não foi possível confirmar o documento",
      "DEFAULT_REPORT_ERROR_MESSAGE": "Falha ao gerar o PDF",
      "Observações interna": "Observações interna",
      "Observações não encontradas": "Observações não encontradas",
      "Resultados Separados": "Resultados Separados",
      "Interferentes": "Interferentes",
      "Por": "Por",
      "Atualizado em": "Atualizado em",
      "Motivo de inválido": "Motivo de inválido",
      "PRIMEIRA FOTO": "PRIMEIRA FOTO",
      "SEGUNDA FOTO": "SEGUNDA FOTO",
      "Fator de correção": "Fator de correção",
      "Interpolações": "Interpolações",
      "Tipo da curva": "Tipo da curva",
      "Valor de concentração": "Valor de concentração",
      "Ponto aplicado": "Ponto aplicado",
      "Quantidade de medidas": "Quantidade de medidas",
      "Brilho": "Brilho",
      "Valor de corrente": "Valor de corrente",
      "Contraste": "Contraste",
      "Corrente (µA)": "Corrente (µA)",
      "Valores espectrômetro": "Valores espectrômetro",
      "Valores espectrômetro branco": "Valores espectrômetro branco",
      "Tempo de processamento": "Tempo de processamento",
      "Transmitância": "Transmitância",
      "Canal": "Canal",
      "Branco": "Branco",
      "MEDIDAS": "MEDIDAS",
      "EXAME DE AMPEROMETRIA CINÉTICA": "EXAME DE AMPEROMETRIA CINÉTICA",
      "Indeterminado": "Indeterminado",
      "Controle": "Controle",
      "Pontos marcados": "Pontos marcados",
      "Zerar todos": "Zerar todos",
      "Dados": "Dados",
      "Comprimento de onda": "Comprimento de onda",
      "Você tem um ponto fora da curva": "Você tem um ponto fora da curva",
      "Ponto inicial": "Ponto inicial",
      "Ponto final": "Ponto final",
      "Reprocessar": "Reprocessar",
      "training": "TREINAMENTO",
      "change_result": "Alteração de resultados",
      "loading_selected_result": "Aguarde, salvando alterações",
      "no_exam_found": "Nenhum exame encontrado",
      "Error searching valid limits of analyte(s) ": "Não foi possível localizar um limit para os analitos: ",
      "An error occurred sending report": "Erro ao enviar resultado"
    },
  },
};

export default translation;