import { Context as TContext, createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useEffect, useState } from "react";
import { AppStateType, IAuthData } from 'types/index';
import { Axios } from '../config/axiosFetcher'

type TProps = {
  children: ReactNode
}

type TFunctionType = () => AppStateType

const Context: TContext<AppStateType> = createContext<AppStateType>({
  auth: {} as IAuthData,
  setAuth: () => null,
  authToken: "",
  setAuthToken: () => null,
  storeLogin: () => null,
  logout: () => null,
  checkLogin: () => null,
  userPermissions: [],
  storeTokenSign: () => null,
  tmTokenExpiration: '',
  setTmTokenExpiration: () => null,
  userLevel: 0,
  setUserLevel: () => null
});

const useApp: TFunctionType = () => useContext<AppStateType>(Context);

const AppState: TFunctionType = () => {
  useEffect(() => {
    const storagedUser: string | null = localStorage.getItem('@App:user');
    const storagedToken: string | null = localStorage.getItem('@App:token');

    if (storagedToken && storagedUser) {
      setAuth(JSON.parse(storagedUser));
      setAuthToken(storagedToken)
    }
  }, []);

  async function storeLogin(data: IAuthData) {
    const timeLimit = String(new Date().getTime() + (data.expires_in * 1000))
    setAuth(data);
    setAuthToken(data.access_token)
    localStorage.setItem('@App:user', JSON.stringify(data));
    localStorage.setItem('@App:timeLimit', timeLimit)
    localStorage.setItem('@App:token', data.access_token);
  }

  async function storeTokenSign(res: string) {
    localStorage.setItem('@App:digitalSignature', res)
    const body = {};
    await Axios.post("/report-software-service/token/signature/refresh", body, {
      headers: {
        tokenSign: res
      }
    }).then((token: any) => {
      localStorage.setItem('@tokenSign', token.data.tokenSign)
    })
  }

  function checkLogin() {
    const storagedToken: string | null = localStorage.getItem('@App:token');
    return !!storagedToken
  }

  function logout() {
    setAuth({} as IAuthData);
    setAuthToken("")
    localStorage.clear()
  }
  const [auth, setAuth]: [IAuthData, Dispatch<SetStateAction<IAuthData>>] = useState<IAuthData>({} as IAuthData);
  const [authToken, setAuthToken]: [string, Dispatch<SetStateAction<string>>] = useState<string>("");
  const [tmTokenExpiration, setTmTokenExpiration]: [string, Dispatch<SetStateAction<string>>] = useState<string>("");
  const [userPermissions, setPermissions] = useState<string[]>([])
  const [userLevel, setUserLevel] = useState<number>(0);
  
  useEffect(() => {
    auth.user && auth.user.permissions && setPermissions(auth.user.permissions.reduce(
      (obj, item) => {
        Object.keys(item)
        return [...obj, ...Object.keys(item)]
      },
      [] as any
    ))

    auth.user && auth.user.permissions && setUserLevel(auth.user.level)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth])

  const appState: AppStateType = { auth, setAuth, setAuthToken, authToken, logout, storeLogin, storeTokenSign, checkLogin, userPermissions, tmTokenExpiration, setTmTokenExpiration, userLevel, setUserLevel }

  return appState;
};

const AppContext: FC<TProps> = ({ children }: TProps): JSX.Element => (
  <Context.Provider value={AppState()}>{children}</Context.Provider>
);

export { AppContext, useApp };
