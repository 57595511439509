import { AppBar, Box, Button, ClickAwayListener, Menu, MenuItem, Paper, Tab, Tabs, Theme, Toolbar, Typography, useMediaQuery, useTheme } from "@mui/material"
import { FC, ReactNode, useEffect, useState } from "react"
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useApp } from "contexts/AppContext";
import React from "react";
import { useHistory } from "react-router";
import { useMQTT } from "contexts/MQTTContext";
import logo from 'assets/logo.svg';
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "./LanguageSwitcher";
import ExpiredPermission from "./ExpiredPermission";
import version from "assets/version.json"

interface IProps {
  isAuthenticaded: boolean
  children: ReactNode
}

const Layout: FC<IProps> = ({ isAuthenticaded, children }: IProps): JSX.Element => {
  const { userPermissions, auth, logout, userLevel } = useApp()
  const { mqttDisconnect } = useMQTT()
  const history = useHistory()
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState<string>('home');
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const { t } = useTranslation();
  const noHeaderLocations = ["analysis"];

  useEffect(() => {
    var locations = history.location.pathname.replace('/', '')
    if (locations && !noHeaderLocations.includes(locations)) {
      setTab(locations)
    } else {
      setTab("home")
    }
    // eslint-disable-next-line
  }, [history.location])
  const handleToggle = () => {
    setOpen((prevOpen: boolean) => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const handleLogout = async (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    await mqttDisconnect()
    await logout()
    setOpen(false)

    history.push("/")
  }

  const handleChangeTab = (event: any, url: string) => {
    history.push("/" + url)
    setTab(url)
  }

  const theme: Theme = useTheme();
  const matchXs = useMediaQuery(theme.breakpoints.down("xs"))

  return (
    <>
      {isAuthenticaded && !noHeaderLocations.includes(history.location.pathname.replace('/', '')) ?
        <Box>
          <AppBar position="relative" color="default" elevation={3}>
            <Toolbar>
              <Box display="flex" alignItems="center" gap={2} mx={1}>
                <img src={logo} alt="Hilab" />
                {/* {!matchXs && <Typography variant="body1">{t('Laudos')}</Typography>} mostrar uma label "laudos" */}

              </Box>
              <Box>
                <Tabs
                  value={tab}
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                  onChange={handleChangeTab}
                  indicatorColor="primary"
                  TabIndicatorProps={{}}
                >
                  <Tab
                    label={t('Fila')}
                    value={'home'}
                    style={{ padding: "16px 24px", marginRight: "16px" }}
                  />
                  <Tab
                    label={t('Arquivo')}
                    value={'file'}
                    style={{ padding: "16px 24px", marginRight: "16px" }}
                  />

                  <Tab
                    label={t('Permissões')}
                    disabled={!userPermissions.includes('technical_manager')}
                    value={'permissions'}
                    style={{ padding: "16px 24px", marginRight: "16px"}}
                  />
                  {userLevel > 5 && (
                    <Tab
                      label={t('Filtros')}
                      disabled={!userPermissions.includes('technical_manager')}
                      value={'filters'}
                      style={{ padding: "16px 24px", marginRight: "16px" }}
                    />
                  )}
                </Tabs>
              </Box>
              <Box flex="1"></Box>

              {!matchXs &&
                <Box mr={2}>
                  <LanguageSwitcher />
                </Box>
              }

              <ExpiredPermission />

              <Typography>
                <MenuItem>V. {version.version}</MenuItem>
              </Typography>

              <Button
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                color="inherit"
                onClick={handleToggle}
                style={{
                  border: '1px solid rgba(0,0,0,0.23)',
                  borderRadius: '50px',
                }}
              >
                <Typography
                  marginRight='8px'
                  color="inherit"
                >
                  {auth.user?.name}
                </Typography>
                <AccountCircle />
              </Button>

              <Menu
                open={open}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                closeAfterTransition
                keepMounted
                anchorEl={anchorRef.current}
                role={undefined}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuItem onClick={handleLogout}>{t('Logout')}</MenuItem>
                  </ClickAwayListener>
                </Paper>

              </Menu>
            </Toolbar>
          </AppBar>

          <Box mx={1}>
            {children}
          </Box>
        </Box>
        :
        <>
          {children}
        </>
      }
    </>
  )
}

export default Layout