import {AxiosError, AxiosRequestConfig, AxiosResponse} from "axios";
import Alert from "components/main/Alert";
import i18next from "i18next";
import {IAuthData} from "types";
import {Axios} from "./axiosFetcher";

async function requestInterceptor(config: AxiosRequestConfig) {
  if (config.url === `/sso/oauth/token`) {
    config.headers = {
      ...config.headers,
    };
  } else {
    try {
      const {refresh_token, user}: IAuthData = JSON.parse(
        localStorage.getItem("@App:user") ?? ""
      );
      const timeLimit = localStorage.getItem("@App:timeLimit");
      const timeStampNow = new Date().getTime();
      if (refresh_token && timeLimit && Number(timeLimit) < timeStampNow) {
        try {
          const body = new FormData();
          body.append("grant_type", "refresh_token");
          body.append("refresh_token", refresh_token);
          localStorage.setItem("@App:user", "")
          const res: any = await Axios.post(`/sso/oauth/token`, body, {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization:
                "Basic " +
                window.btoa(
                  `${process.env.REACT_APP_USER}:${process.env.REACT_APP_PASSWORD}`
                ),
            },
          });
          if (res.data) {
            config.headers.Authorization = ""
            const body = {};
            const newTimeLimit = String(
              new Date().getTime() + res.data.expires_in * 1000
            );
            localStorage.setItem(
              "@App:user",
              JSON.stringify({
                ...res.data,
                user,
              })
            );
            localStorage.setItem("@App:token", res.data.access_token);
            localStorage.setItem("@App:timeLimit", newTimeLimit);
            if (res.data.access_token) {
              config.headers.Authorization = `Bearer ${res.data.access_token}`;
              config.headers = {
                ...config.headers,
                auth: {
                  username: process.env.REACT_APP_USER!,
                  password: process.env.REACT_APP_PASSWORD!,
                },
              };
            }
            const newTokenSign = localStorage.getItem("@tokenSign")
            if (newTokenSign) {
              await Axios.post(
                "/report-software-service/token/signature/refresh",
                body,
                {
                  headers: {
                    tokenSign: newTokenSign,
                  },
                }
              ).then((refreshToken: any) => {
                if (refreshToken && refreshToken.data && refreshToken.data.tokenSign) {
                  localStorage.setItem("@tokenSign", refreshToken.data.tokenSign)
                  config.headers = {
                    ...config.headers,
                    tokenSign: refreshToken.data.tokenSign
                  }
                }
              })
            }
          }
        } catch (err) {
          // window.locaion.href = '/'
        }
      } else {
        const token = localStorage.getItem("@App:token");
        if (token) config.headers.Authorization = `Bearer ${token}`;
        config.headers = {
          ...config.headers,
          auth: {
            username: process.env.REACT_APP_USER!,
            password: process.env.REACT_APP_PASSWORD!,
          },
        };
      }
    } catch (err) {
      const token = localStorage.getItem("@App:token");
      if (token) config.headers.Authorization = `Bearer ${token}`;
      config.headers = {
        ...config.headers,
        auth: {
          username: process.env.REACT_APP_USER!,
          password: process.env.REACT_APP_PASSWORD!,
        },
      };
    }
  }

  return config;
}

function requestInterceptorError(error: AxiosError) {
  return Promise.reject(error);
}

function responseInterceptor(response: AxiosResponse) {
  // console.info('Response Interceptor')
  return response;
}

function responseInterceptorError(error: any) {
  if (error.response) {
    try {
      if (error.response?.status === 401) {
        localStorage.clear();
        window.location.href = "/";
        window.parent &&
        window.parent.postMessage(
          {
            cmd: "unauthorized",
            params: {},
          },
          "*"
        );
      } else if (error.response?.status === 422) {
        Alert.fire({
          toast: true,
          position: "bottom-start",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          icon: "error",
          text: i18next.t("DEFAULT_REPORT_ERROR_MESSAGE"),
          customClass: {
            container: "stylesAlert ",
          },
        });
      } else {
        let msg = "error";
        if (error.response?.data?.details) {
          msg = i18next.t(error.response?.data?.details[0]?.description) + (error.response?.data?.details[1] ? error.response?.data?.details[1]?.description : '');
        } else if (error.response?.data?.error_description) {
          msg = i18next.t(error.response?.data?.error_description);
        }
        Alert.fire({
          toast: true,
          position: "bottom-start",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          icon: "error",
          text: msg,
          customClass: {
            container: "stylesAlert ",
          },
        });
      }
    } catch (err) {
      return Promise.reject(error);
    }
  }
  return Promise.reject(error);
}

export {
  requestInterceptor,
  requestInterceptorError,
  responseInterceptor,
  responseInterceptorError,
};
