import { Box, Badge, Tooltip } from "@mui/material"
import { useTranslation } from "react-i18next"
import { LockOpen, LockRounded } from "@mui/icons-material";
import { formatExpirationDate } from "helpers/formatExpirationDate";

const ExpiredPermission: React.FC = () => {

  const { t } = useTranslation();

  let timeTokenLocal = localStorage.getItem("@tmTokenExpiration")
  if (timeTokenLocal) {
    let timeTokenExpirations = (+ timeTokenLocal)
    let timeExpiration = new Date().getTime()

    if (timeTokenExpirations && timeTokenExpirations > timeExpiration) {
      let dayNow = new Date().getDate()

      let dateUntilTokenExpiration = formatExpirationDate(timeTokenExpirations, false);

      let todayTokenExpiration = formatExpirationDate(timeTokenExpirations, true);

      let dayTm = new Date(timeTokenExpirations).getDate()
      let timeNow = new Date().getTime()
      let dayExpiration = (timeTokenExpirations - timeNow) / 86400000
      let expiration = dayExpiration.toFixed(0)

      return (
        <Box mr={3}>
          <Tooltip title={`${dayNow === dayTm ? `${t('Expira hoje')} ${todayTokenExpiration}` : `${t('Expira em ')} ${expiration} ${t('dias')} (${dateUntilTokenExpiration})`}`}>
            <Badge badgeContent={expiration ? expiration : '!'} color="error">
              <LockOpen />
            </Badge>
          </Tooltip>
        </Box>
      )
    } else {
      return (
        <Box mr={3}>
          <Tooltip title={`${t('Permissão expirada')}`}>
            <Badge badgeContent={'!'} color="error">
              <LockRounded />
            </Badge>
          </Tooltip>
        </Box>
      )
    }
  }
  return <></>
}
export default ExpiredPermission;